.page.profile-page {
  .header {
    height: 120rem;
    background: #E8ECE7;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;

    .back {
      position: absolute;
      top: 28rem;
      left: 8%;
      width: 22rem;
      height: 22rem;
      background: url(/assets/img/arrow-left.png);
      background-size: cover;
    }
  }

  .avatar {
    width: 96rem;
    height: 96rem;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -48rem;
  }

  .email {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    font-size: 18rem;
  }

  button.exit {
    position: absolute;
    width: 50%;
    top: 60%;
    left: 50%;
    margin-left: -25%;
  }
}
