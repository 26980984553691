.page.qrcode-page {
  text-align: center;

  .image-wrapper {
    margin-left: 20%;
    width: 60%;
    height: 200rem;
    display: block;
    background-color: #ffffff;
    border-radius: 16rem;
    text-align: center;
    margin-left: 20%;
    margin-top: 20%;
  }

  .title {
    margin-left: 20%;
    margin-top: 30rem;
    width: 60%;
    font-family: Inter-Bold;
    font-size: 18rem;
    text-align: left;
  }

  .text {
    margin-left: 20%;
    margin-top: 100rem;
    width: 60%;
    font-family: Inter;
    font-size: 14rem;
    text-align: left;
  }

  .buttons {
    margin-left: 20%;
    margin-top: 10rem;
    width: 60%;
    text-align: left;

    button {
      width: 48%;
      &:nth-child(1) {
        margin-right: 4%;
      }
    }
  }

}
