.alert {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  .window {
    position: absolute;
    padding: 32rem 0;
    width: 80%;
    left: 10%;
    border-radius: 24rem;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(173.76deg, #E8ECE7 30.84%, #DFC6EE 123.87%);

    .icon {
      width: 48rem;
      height: 48rem;
      margin-left: 50%;
      transform: translateX(-24rem);
      &.error {
        background: url(/assets/img/alert-error.png);
        background-size: cover;
      }

      &.success {
        background: url(/assets/img/alert-smile.png);
        background-size: cover;
      }

      &.info {
        background: url(/assets/img/alert-info.png);
        background-size: cover;
      }

      &.question {
        background: url(/assets/img/alert-question.png);
        background-size: cover;
      }
    }

    .text {
      font-size: 20rem;
      text-align: center;
      padding: 32rem;
    }

    .buttons {
      padding: 0 32rem;
      text-align: center;

      button {
        width: auto;
        display: inline-block;
        min-width: 110rem;
        margin: 0 4rem;
      }
    }
  }


}
