.page.notes-page {

  &.scrolled {
    .header {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      background: #E8ECE7;
    }
  }

  .header {
    height: 80rem;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;


    .title {
      margin-top: 26rem;
      margin-left: 32rem;
      font-size: 26rem;
      font-family: Inter-Bold;
      display: inline-block;
    }

    .profile {
      position: absolute;
      top: 24rem;
      right: 32rem;
      width: 32rem;
      height: 32rem;
      border-radius: 50%;
      overflow: hidden;
      &.anonymous {
        background: url(/assets/img/profile.png) no-repeat center;
        background-size: 22rem 22rem;
      }
    }

    .cloud-wrapper {
      position: absolute;
      top: 20rem;
      right: 32rem;
      width: 42rem;
      height: 42rem;
      padding: 0;

      .cloud {
        width: 100%;
        height: 100%;
        background: url(/assets/img/refresh-black.png) no-repeat;
        background-size: contain;

        &.loading {
          animation: rotation 2s infinite linear;
        }
      }

      &.has-changes {
        &:after {
          content: "";
          position: absolute;
          top: -6rem;
          right: -6rem;
          width: 12rem;
          height: 12rem;
          border-radius: 50%;
          background-color: #e70a0a;
        }
      }
    }
  }

  .content {
    padding: 90rem 32rem 20rem 32rem;

    a {
      text-decoration: none;
    }

    .note {
      position: relative;
      max-height: 110rem;
      min-height: 30rem;
      background: white;
      font-size: 16rem;
      margin-top: 8rem;
      border-radius: 16rem;
      padding: 18rem;
      overflow: hidden;

      .remove {
        position: absolute;
        width: 26rem;
        height: 26rem;
        right: 10rem;
        top: 20rem;
        margin-top: -10rem;
        background: url(/assets/img/remove-item.png) no-repeat;
        background-size: contain;
        z-index: 1;
      }



      .title {
        font-size: 18rem;
        color: black;
        font-family: Inter-Bold;
        margin-bottom: 10rem;
      }

      .payload {
        position: relative;
        .list-item {
          margin-left: 20rem;

          .text {
            position: relative;
            margin-bottom: 5rem;

            &:before {
              content: "";
              position: absolute;
              width: 12rem;
              height: 12rem;
              background: red;
              left: -20rem;
              top: 4rem;
              background: url(/assets/img/checkbox-0.png);
              background-size: cover;
            }
            &[data-done=true]:before {
              background: url(/assets/img/checkbox-1.png);
              background-size: cover;
            }
          }
        }


      }

      &:after {
        content: "";
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .empty {
      padding: 180rem 70rem 0rem 50rem;

      .icon {
        width: 60rem;
        height: 60rem;
        background: url(/assets/img/empty.png);
        background-size: cover;
      }

      .text {
        font-size: 18rem;
        margin-top: 42rem;
        margin-bottom: 40rem;
        width: 200rem;
      }
    }
  }

  .buttons {
    position: fixed;
    bottom: 30rem;
    left: 0rem;
    width: 100%;
    text-align: center;

    button {
    }
  }
}
