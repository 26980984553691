.page.main-page {
  /*padding: 105rem 65rem 0 65rem;*/

  .logo {
    margin-top: 85rem;
    margin-left: 20%;
  }

  .title {
    font-size: 20rem;
    font-family: Inter-Bold;
    margin-top: 65rem;
    margin-left: 20%;
    width: 60%;
  }

  .subtitle {
    font-size: 20rem;
    font-weight: 500;
    margin-top: 30rem;
    margin-left: 20%;
    width: 60%;
  }

  .please-auth {
    font-size: 16rem;
    margin-top: 60rem;
    margin-bottom: 24rem;
    margin-left: 20%;
    width: 60%;
  }

  button {
    width: 60%;
    display: block;
    margin-top: 8rem;
    margin-left: 20%;
  }


  .skip {
    margin-top: 30rem;
    margin-left: 20%;
    font-size: 16rem;
    text-decoration: underline;
  }

}
