.page.note-page {

  .content {
    /*padding-top: 140rem;*/
    padding: 140rem 8% 40rem 8%;
    min-height: 70%;

    .plaintext {
      display: inline-block;
      /*background-color: white;*/
      font-size: 20rem;
      line-height: 28rem;
      min-height: 100%;
      white-space: pre-wrap;

      &:focus {
        outline: none;
      }

      &:empty:before {
        content: attr(placeholder);
        color: #757575;
      }
    }


  }
}
