.page.note-page {

  .content {

    .list-item {
      position: relative;
      border-radius: 12rem;
      background-color: white;
      padding: 15rem 15rem 10rem 45rem;
      margin-bottom: 4rem;

      .checkbox {
        content: "";
        position: absolute;
        width: 18rem;
        height: 18rem;
        left: 15rem;
        top: 15rem;
        background: url(/assets/img/checkbox-0.png);
        background-size: cover;
      }

      &[data-done=true] {
        .checkbox {
          background: url(/assets/img/checkbox-1.png);
          background-size: cover;
        }
        .text {
          color: #aaaaaa;

        }
      }

      .remove {
        border-radius: 50%;
        border: 2rem solid #ffffff;
        position: absolute;
        width: 22rem;
        height: 22rem;
        right: -13rem;
        top: 13rem;
        background: url(/assets/img/remove-item.png) no-repeat;
        background-size: cover;
      }

      .text {
        margin-bottom: 5rem;
        font-size: 16rem;
        outline: none;

        &:empty:before {
          content: attr(placeholder);
          color: #bbbbbb;
          font-style: italic;
        }
      }

      &[data-selected=true] {
        /*border: 1px solid #948f8f;*/
        /*background-color: #dddddd;*/


      }
    }

    .add-item {
      position: relative;
      margin-top: 18rem;
      margin-left: 45rem;
      font-size: 16rem;

      &:before {
        content: "";
        position: absolute;
        width: 20rem;
        height: 20rem;
        left: -31rem;
        top: 0rem;
        background: url(/assets/img/add-item.png);
        background-size: cover;
      }
    }
  }
}
