.page.note-page {

  &.scrolled {
    .header {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      background: #E8ECE7;
    }
  }

  .header {
    height: 120rem;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;

    .back {
      position: absolute;
      top: 28rem;
      left: 8%;
      width: 22rem;
      height: 22rem;
      background: url(/assets/img/arrow-left.png);
      background-size: cover;
    }

    .info {
      font-size: 18rem;
      float: right;
      margin-top: 28rem;
      margin-right: 8%;
    }

    .title-input {
      border: none;
      font-size: 24rem;
      position: relative;
      top: 6rem;
      left: 8%;
      width: 90%;
      font-family: Inter-Bold;
      background-color: transparent;
      padding: 5rem 0 5rem 0;

      &:focus {
        outline: none;
      }
    }
  }

  .content {
    /*padding-top: 140rem;*/
    padding: 140rem 32rem 40rem 32rem;
    min-height: 70%;


  }
}
