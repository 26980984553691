@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(/assets/fonts/Inter-Bold.ttf);
}

* {
  font-family: Inter;
  color: black;
}

html {
  font-size: 1px;
  height: 100%;
  background-color: #DFC6EE;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 40rem;
  background: linear-gradient(180deg, #E8ECE7, #DFC6EE);



}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  position: fixed;
  top: 0;

  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 500rem;


  -webkit-overflow-scrolling: touch;
}

.bg {
  width: 100%;
  height: 100%;
  background: red;
  position: fixed;
  top: 0;
  background: linear-gradient(173.76deg, #E8ECE7 30.84%, #DFC6EE 123.87%);
  z-index: 0;
}


.page {
  /*position: fixed;*/
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  /*================MAIN page================*/
  /*================================*/


  /*================================*/
  /*================================*/



  /*================================*/
  /*================================*/



  /*================================*/
  /*================================*/

}

/*================================*/
/*================================*/
button {
  background-color: #000000;
  color: #ffffff;
  height: 40rem;
  text-align: center;
  font-size: 14rem;
  border-radius: 8rem;
  outline: none;
  border: none;

  &.main {
    position: fixed;
    width: 64rem;
    height: 64rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    box-shadow: 0 0 10rem #aaa;
  }

  &.create {
    background: #000000 url(/assets/img/plus.png) no-repeat center;
    background-size: 35rem 35rem;
    left: 50%;
    margin-left: -32rem;
    bottom: 40rem;

    &.close {
      background: #ffffff url(/assets/img/close.png) no-repeat center;
      background-size: 55rem 55rem;

    }

    .create-checklist {
      position: fixed;
      width: 56rem;
      height: 56rem;
      left: 50%;
      margin-left: 13rem;
      bottom: 110rem;

      background: #000000 url(/assets/img/checklist.png) no-repeat center;
      background-size: 35rem 35rem;
    }

    .create-plaintext {
      position: fixed;
      width: 56rem;
      height: 56rem;
      left: 50%;
      margin-left: -70rem;
      bottom: 110rem;
      background: #000000 url(/assets/img/plaintext.png) no-repeat center;
      background-size: 35rem 35rem;
    }
  }

  &.update {
    width: 56rem;
    height: 56rem;
    left: 50%;
    margin-left: 50rem;
    bottom: 43rem;
    background: #000000 url(/assets/img/refresh-white.png) no-repeat center;
    background-size: 35rem 35rem;
    &.loading {
      animation: rotation 2s infinite linear;
    }

    &.has-changes {
      &:after {
        content: "";
        position: absolute;
        top: -4rem;
        right: -4rem;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background-color: #e70a0a;
      }
    }
  }
}

.loading.has-changes:after {
  display: none;
}

/*================================*/
/*================================*/




/* ========================================= */
/* ========================================= */
/* ========================================= */

.pageIn {
  animation: 0.5s pageIn forwards;
}

.pageOut {
  animation: 0.1s pageOut forwards;
}

@keyframes pageIn {
  from {
    opacity: 0;
    transform: translate(0rem, 40rem);
  }
  to {
    opacity: 1;
    transform: translate(0rem, 0rem);
  }
}

@keyframes pageOut {
  from {
    opacity: 1;
    transform: translate(0rem, 0rem);
  }
  to {
    transform: translate(0rem, 0rem);
    opacity: 0;
  }
}

/*==================*/
.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*============================*/
.loader-wrapper {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    background: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;


    .lds-dual-ring {
      position: absolute;
      display: inline-block;
      width: 50rem;
      height: 50rem;
      top: 50%;
      left: 50%;

      margin-top: -25rem;
      margin-left: -25rem;
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 40rem;
      height: 40rem;
      border-radius: 50%;
      border: 5rem solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}


@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


